<template>
  <div class="report-stat">
    <el-form inline>
      <el-form-item label="工程类型" style="margin-bottom: 0">
        <el-select v-model="currentEngineeringType">
          <el-option
            v-for="engineeringType in engineeringTypes"
            :key="engineeringType.dictionaryGuid"
            :label="engineeringType.dictionaryValue"
            :value="engineeringType.dictionaryValue">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div
      v-for="stat of filteredStatList"
      :key="stat.url"
      @click="handleClick(stat)"
      class="button-wrapper">
      <i class="el-icon-pie-chart"></i>
      <span>{{ stat.name }}</span>
    </div>
  </div>
</template>

<script>
import dictionaryService from '@/services/dictionaryService'
import PageInfo from '@/support/PageInfo'
import config from '@/config'

export default {
  name: 'ReportStat',
  computed: {
    filteredStatList () {
      return this.statList.filter(item => item.types.indexOf(this.currentEngineeringType) > -1)
    }
  },
  data () {
    return {
      engineeringTypes: [],
      currentEngineeringType: '土建',
      statList: [
        {
          url: 'dateTenderStat',
          name: '日形象进度汇总',
          types: ['土建', '交安']
        },
        {
          url: 'g19DateTenderStat',
          name: '日备料汇总',
          types: ['土建', '交安']
        },
        {
          url: 'g19DateTenderStatSummary',
          name: '日备料汇总1',
          types: ['土建', '交安']
        },
        {
          url: 'g20TenderStat1',
          name: '日机械汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g20TenderStat2',
          name: '日人员汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g01TenderStat',
          name: 'g01月统计汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g01Detail1TenderStat',
          name: 'g01月统计明细表1',
          types: ['土建', '交安']
        },
        {
          url: 'g01Detail2TenderStat',
          name: 'g01月统计明细表2',
          types: ['土建', '交安']
        },
        {
          url: 'g01Detail3TenderStat',
          name: 'g01月统计明细表3',
          types: ['土建', '交安']
        },
        {
          url: 'g11TenderStat',
          name: 'g11月计划汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g11Detail1TenderStat',
          name: 'g11月计划明细表1',
          types: ['土建', '交安']
        },
        {
          url: 'g11Detail2TenderStat',
          name: 'g11月计划明细表2',
          types: ['土建', '交安']
        },
        {
          url: 'g26TenderStat',
          name: 'g26月计划汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g26Detail1TenderStat',
          name: 'g26月计划明细表1',
          types: ['土建', '交安']
        },
        {
          url: 'g26Detail2TenderStat',
          name: 'g26月计划明细表2',
          types: ['土建', '交安']
        },
        {
          url: 'g27TenderStat',
          name: 'g27月计划汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g27Detail1TenderStat',
          name: 'g27月计划明细表1',
          types: ['土建', '交安']
        },
        {
          url: 'g27Detail2TenderStat',
          name: 'g27月计划明细表2',
          types: ['土建', '交安']
        },
        {
          url: 'g02TenderStat',
          name: 'g02月统计汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g02Detail1TenderStat',
          name: 'g02月统计明细表1',
          types: ['土建', '交安']
        },
        {
          url: 'g02Detail2TenderStat',
          name: 'g02月统计明细表2',
          types: ['土建', '交安']
        },
        {
          url: 'g02Detail3TenderStat',
          name: 'g02月统计明细表3',
          types: ['土建', '交安']
        },
        {
          url: 'g04TenderStat',
          name: 'g04月统计汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g04Detail1TenderStat',
          name: 'g04月统计明细表1',
          types: ['土建', '交安']
        },
        {
          url: 'g04Detail2TenderStat',
          name: 'g04月统计明细表2',
          types: ['土建', '交安']
        },
        {
          url: 'g04Detail3TenderStat',
          name: 'g04月统计明细表3',
          types: ['土建', '交安']
        },
        {
          url: 'g13TenderStat',
          name: 'g13月统计汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g13Detail1TenderStat',
          name: 'g13月统计明细表1',
          types: ['土建', '交安']
        },
        {
          url: 'g13Detail2TenderStat',
          name: 'g13月统计明细表2',
          types: ['土建', '交安']
        },
        {
          url: 'g13Detail3TenderStat',
          name: 'g13月统计明细表3',
          types: ['土建', '交安']
        },
        {
          url: 'g08TenderStat',
          name: 'g08年计划汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g24TenderStat',
          name: 'g24年计划汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g10TenderStat',
          name: 'g10年计划汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g25TenderStat',
          name: 'g25年计划汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g05TenderStat',
          name: 'g05年统计汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g14TenderStat',
          name: 'g14年统计汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g16TenderStat',
          name: 'g16年统计汇总表',
          types: ['土建', '交安']
        },
        {
          url: 'g17TenderStat',
          name: 'g17年统计汇总表',
          types: ['土建', '交安']
        }
      ]
    }
  },
  methods: {
    handleClick (stat) {
      this.$router.push(`${stat.url}?engineeringType=${this.currentEngineeringType}`)
    },
    _getEngineeringTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'tenderEngineeringType' })
        .then(res => {
          if (res.data.code === 1) {
            this.engineeringTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getEngineeringTypes()
  }
}
</script>

<style scoped lang="scss">
.button-wrapper {
  float: left;
  margin: 15px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 150px;
  i {
    font-size: 90px;
    margin-bottom: 10px;
  }
}
.button-wrapper:hover {
  color: rgb(23, 162, 184);
}
</style>
